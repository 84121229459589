<template>
  <section v-loading="isLoading" class="coupon-page">
    <div>
      <div class="page-header xa-cell">
        <div class="search-box">
          <el-input
            placeholder="搜索优惠券名称"
            v-on:keyup.enter.native="onSearchQuery(true)"
            v-model="query.keyword"
            size="small"
          >
            <i
              @click="onSearchQuery(true)"
              slot="prefix"
              :class="{ 'xa-color-info': !!query.keyword }"
              class="el-input__icon el-icon-search"
            ></i>
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="onSearchQuery"
            ></el-button>
          </el-input>
        </div>
        <el-button
          style="margin-left:8px;"
          icon="el-icon-remove-outline"
          size="small"
          @click="onResetQuery"
          >重置</el-button
        >
      </div>
      <div class="page-table">
        <el-table
          ref="multipleTable"
          height="400"
          :data="tableData"
          tooltip-effect="dark"
        >
          <el-table-column label="" width="50">
            <template slot-scope="scope">
              <el-checkbox
                v-model="scope.row._checked"
                @input="onCheckItem(scope.row)"
              ></el-checkbox>
            </template>
          </el-table-column>
          <el-table-column prop="title" label="标题" />
          <el-table-column prop="use_limit_content_extend" label="内容" />
          <el-table-column prop="discount" label="金额(¥)" />
          <el-table-column prop="surplus_cnt" label="剩余库存" />
        </el-table>
      </div>
      <div class="page-pagination xa-cell">
        <div class="xa-flex">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="query.page_index"
            :page-sizes="[10, 20, 40]"
            :page-size="query.page_size"
            layout="total, sizes, prev, pager, next"
            :total="totalDataCount"
          />
        </div>
        <div class=" xa-cell">
          <div
            v-if="multipleSelection.length"
            class="xa-cursor"
            style="margin-right:16px;"
          >
            已选:优惠券
            <span class="xa-txt-blue">({{ multipleSelection.length }})</span>
          </div>
          <el-button size="small" @click="onClose">取消</el-button>
          <el-button
            :disabled="multipleSelection.length == 0"
            type="primary"
            size="small"
            @click="onSubimt"
            >确定</el-button
          >
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { getCouponList } from '@target/apis/coupon'
export default {
  data() {
    return {
      isLoading: true,
      totalDataCount: 0,
      query: {
        page_index: 1,
        page_size: 10,
        keyword: ''
      },
      tableData: [],
      multipleSelection: []
    }
  },
  methods: {
    onResetQuery() {
      this.query.page_index = 1
      this.query.keyword = ''
      this.query.selectedOptions = []
      this.onSearchQuery()
    },
    onSubimt() {
      if (this.multipleSelection.length) {
        this.$emit('submit', this.multipleSelection)
        this.multipleSelection = []
        this.tableData.forEach(item => (item._checked = false))
      }
    },
    onClose() {
      this.$emit('close')
    },
    handleSizeChange(val) {
      this.query.page_size = val
      this.onSearchQuery()
    },
    handleCurrentChange(val) {
      this.query.page_index = val
      this.onSearchQuery()
    },
    onSearchQuery(isFormSearch) {
      if (isFormSearch) {
        this.query.page_index = 1
        this.query.selectedOptions = []
      }
      this.queryData(this.query)
    },
    deleteItem(item) {
      const index = this.multipleSelection.findIndex(
        has => has.guid === item.guid
      )
      this.multipleSelection.splice(index, 1)
    },
    onCheckItem(item) {
      if (item._checked) {
        this.multipleSelection.push(item)
      } else {
        this.deleteItem(item)
      }
    },
    handleHasSelectedData(items) {
      return items.map(add => {
        add._checked = false
        const target = this.multipleSelection.find(has => has.guid === add.guid)
        return target || add
      })
    },
    async queryData(query = {}) {
      this.isLoading = true
      const searchData = await getCouponList(query)
      this.tableData = this.handleHasSelectedData(searchData.tableData)
      this.totalDataCount = parseInt(searchData.pagination.total)
      this.$nextTick(() => {
        this.isLoading = false
      })
    }
  },
  created() {
    try {
      this.queryData(this.query)
    } finally {
      this.isLoading = false
    }
  }
}
</script>
<style lang="scss" scoped>
.img-wrap {
  width: 60px;
  height: 60px;
  overflow: hidden;
  border: 1px dotted #ccc;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  margin-right: 4px;
  cursor: pointer;
}
.page-pagination {
  padding: 8px 0;
}
.page-btns {
  border-top: 1px solid #e4e4e4;
  padding: 8px 0;
  justify-content: flex-end;
}
</style>
